import React, { useRef } from 'react';
import './contact.css'
import emailjs from '@emailjs/browser';
import { useSnackbar } from 'notistack';

const Contact = () => {
    const form = useRef();
    const { enqueueSnackbar } = useSnackbar();
    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_v4zyv2f', 'template_uuuqih9', form.current, 'N2XYhkTXJ-xebkrby')
        .then((result) => {
        enqueueSnackbar('Message sent successfully', { variant: 'success' });
        }, (error) => {
            enqueueSnackbar('Message not sent !', { variant: 'error' });
        });
    };

  return (
    <section id='contact'>
        <div className='contact-container'>
            <h1>Contact Me</h1>
            <p>Questions or just want to say hi? Shoot me a message! 📨</p>
                <div className='contact-form'>
                    <form className="contact--form--container"  ref={form} onSubmit={sendEmail}>
                        <div className="container">
                        <label htmlFor="first-name" className="contact--label">
                            <span className="text-md">First Name</span>
                            <input
                            type="text"
                            className="contact--input text-md"
                            name="user_fname"
                            id="first-name"
                            required
                            />
                        </label>
                        <label htmlFor="last-name" className="contact--label">
                            <span className="text-md">Last Name</span>
                            <input
                            type="text"
                            className="contact--input text-md"
                            name="user_lname"
                            id="last-name"
                            required
                            />
                        </label>
                        <label htmlFor="email" className="contact--label">
                            <span className="text-md">Email</span>
                            <input
                            type="email"
                            className="contact--input text-md"
                            name="user_email"
                            id="email"
                            required
                            />
                        </label>
                        <label htmlFor="phone-number" className="contact--label">
                            <span className="text-md">Phone Number</span>
                            <input
                            type="number"
                            className="contact--input text-md"
                            name="user_phone"
                            id="phone-number"
                            required
                            />
                        </label>
                        </div>
                        <label htmlFor="message" className="contact--label">
                            <span className="text-md">Message</span>
                            <textarea
                                className="contact--input text-md"
                                name="message"
                                id="message"
                                rows="4"
                                placeholder="Type your message..."
                            />
                        </label>
                        <div>
                            <button className="btn btn-primary btn-contact" type="submit">Submit</button>
                        </div>
                    </form>
                </div>
                {/* <div className='contact-image'>
                    <img className='contact-img' src="./img/contact-us.png" alt="" />
                </div> */}
        </div>
    </section>
  )
}

export default Contact