import React, { useState, useEffect } from 'react'
import { useTypewriter, Cursor } from 'react-simple-typewriter'
import './main.css'

const Main = () => {

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1200px)');
    setIsSmallScreen(mediaQuery.matches);
  }, []);
  
  const [typeEffect] = useTypewriter({
    words: [`Developer`],
    loop: 1,
    typeSpeed: 200,
  })

  const para1 = 'Versatile Full Stack Developer adept at crafting seamless web solutions.'
  const para2 = 'Expertise in front-end and back-end technologies, '
  const para3 = 'ensuring optimal functionality and user experience.'

  return (
    <section id="mainSection" className="main-section">
      <div className='main-section-content'>
        <div className='main-section-content-heading'>
          <p>Hey, I'm Fayis</p>
          <p>Full Stack <span className='heading-gradiant-color'>{typeEffect}</span><Cursor/></p>
        </div>
        <div className='main-section-content-para'>
          {isSmallScreen ? (
            <div>
              <p>{para1}</p>
              <p>{para2}</p>
              <p>{para3}</p>
            </div>
          ) : (
            <div>
              {/* Render the paragraphs */}
              <p>
                {para1.split(' ').map((word, index) => (
                  <span key={index}>
                    {word.split('').map((char, index) => (
                      <span
                        className='para-char'
                        key={index}
                        onMouseEnter={(e) => e.target.classList.add('up')}
                        onMouseLeave={(e) => e.target.classList.remove('up')}
                      >
                        {char}
                      </span>
                    ))}
                    &nbsp;
                  </span>
                ))}
              </p>
              <p>
                {para2.split(' ').map((word, index) => (
                  <span key={index}>
                    {word.split('').map((char, index) => (
                      <span
                        className='para-char'
                        key={index}
                        onMouseEnter={(e) => e.target.classList.add('up')}
                        onMouseLeave={(e) => e.target.classList.remove('up')}
                      >
                        {char}
                      </span>
                    ))}
                    &nbsp;
                  </span>
                ))}
              </p>
              <p>
                {para3.split(' ').map((word, index) => (
                  <span key={index}>
                    {word.split('').map((char, index) => (
                      <span
                        className='para-char'
                        key={index}
                        onMouseEnter={(e) => e.target.classList.add('up')}
                        onMouseLeave={(e) => e.target.classList.remove('up')}
                      >
                        {char}
                      </span>
                    ))}
                    &nbsp;
                  </span>
                ))}
              </p>
            </div>
          )}
        </div>
        <div className='main-section-video'>
          <video className='main-video' width="100%" height="100%" autoPlay muted loop>
            <source src='./files/main.mp4' type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </div>
      
    </section>
  )
}

export default Main