import './about.css'

export default function About() {
    return (
      <section id="about" className="about-section">
        <div className="about-section-img">
          <img src="./files/img2.jpg" alt="About Me" />
        </div>
        <div className="about-section-content">
            <p className="about-section-title">About</p>
            <h1 className="about-section--heading">About Me</h1>
            <p className="about-section-description">
              Dynamic and accomplished software developer with over 4 years of experience in
              programming, including 2+ years of experience in C# Windows Form Application
              development, combined with 2+ year of experience in Full Stack web development.
              Proficient in C# and JavaScript, along with strong knowledge in Python, React JS, Node
              JS, HTML, CSS, and a demonstrated ability to solve complex problems.
            </p>
        </div>
      </section>
    );
  }