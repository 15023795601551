import React, { useContext, useEffect  } from 'react'
import './skills.css'
import { useInView } from 'react-intersection-observer';
import {SkillContext} from '../../../contexts/SkillContext'
import data from '../../../data/index.json'


const Skills = () => {
  const {setSkillActive} = useContext(SkillContext)
  const { ref:skillRef, inView:skillActive } = useInView({
    threshold: 0,
    rootMargin: '-15% 0px -100% 0px',
  });

  useEffect(() => {
    setSkillActive(skillActive);
  }, [skillActive, setSkillActive]);

  return (
    <section id='skills' ref={skillRef} className={skillActive ? 'active' : ''}>
      <div className="skills-container">
        <div className='skills-heading'>
            <p>My Skills</p>
            <p>My Expertise</p>
        </div>
        <div className='skills-content'>
          {data?.skills?.map((item, index) => (
            <div key={index} className="skills-section-card">
              <div className="skills-section-card-content">
                <p className="skills-section-title">{item.title}</p>
                <p className='skills-section-skills'> {item.skillsList} </p>
                <p className="skills-section-description">{item.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Skills