import { Link } from "react-scroll";
import React from "react";
import './footer.css'
import { FaInstagram, FaLinkedin } from "react-icons/fa6";

function Footer() {
  return (
    <footer className="footer-container">
        <div className="footer-top-pattern"></div> 
        <div className="footer-link-container">
          <div className="footer-contact-details">
            <p><a href="mailto:fayiskareemkt@gmail.com">fayiskareemkt@gmail.com</a></p>
            <p><a href="https://wa.me/+971566264129">+971566264129</a></p>
          </div>
          <div className="footer-items">
            <ul>
              <li>
                <Link
                  activeClass="footer-active-content"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to="mainSection"
                  className="text-md"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  activeClass="footer-active-content"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to="skills"
                  className="text-md"
                >
                  SKILLS
                </Link>
              </li>
              <li>
                <Link
                  activeClass="footer-active-content"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to="portfolio"
                  className="text-md"
                >
                  PORTFOLIO
                </Link>
              </li>
              <li>
                <Link
                  activeClass="footer-active-content"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to="about"
                  className="text-md"
                >
                  ABOUT ME
                </Link>
              </li>
              <li>
                <Link
                  activeClass="footer-active-content"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to="contact"
                  className="text-md"
                >
                  CONTACT ME
                </Link>
              </li>
            </ul>
          </div>
          <div className="footer-social-icon">
            <ul>
              <li>
                <a
                  href="https://www.instagram.com/fayisontech/"
                  className="footer-content"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaInstagram size={25} />
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/fayis-kt/"
                  className="footer-content"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedin size={25} />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="divider" />
        <div className="footer-content-container">
          <p className="text-sm">© {new Date().getFullYear()} Fayis K T. All rights reserved.</p>
          <div className="footer-social-icon">
            <ul>
              <li>
                <Link
                  activeClass="footer-active-content"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to="Privacy_Policy"
                  className="text-sm"
                >
                  Privacy Policy
                </Link>
              </li>
              <li>
                <Link
                  activeClass="footer-active-content"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to="Terms_of_Service"
                  className="text-sm"
                >
                  Terms of Service
                </Link>
              </li>
              <li>
                <Link
                  activeClass="footer-active-content"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                  to="Cookies_Settings"
                  className="text-sm"
                >
                  Cookies Settings
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </footer>
  );
}

export default Footer;
