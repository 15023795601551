import React, { createContext, useState } from 'react';

export const SkillContext = createContext();

export const SkillContextProvider = ({ children }) => {
  const [skillActive, setSkillActive] = useState(false);

  return (
    <SkillContext.Provider value={{ skillActive, setSkillActive }}>
      {children}
    </SkillContext.Provider>
  );
};

export default SkillContextProvider;
