import React from 'react'
import './portfolio.css'
import data from '../../../data/index.json'
import { FaGithub } from "react-icons/fa";

const Portfolio = () => {
  return (
    <section id='portfolio'>
        <div className='portfolio-container'>
            <p className='portfolio-title'>Recent Projects</p>
            <div className='portfolio-cards-container'>
                {data?.portfolio?.map((item, index) => (
                    <div key={index} className={`portfolio-section-card ${index % 2 !== 0 ? 'even-card' : ''}`}>
                        <div className='portfolio-video'>
                            <video width="100%" height="100%" autoPlay muted loop>
                                <source src={item.src} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        </div>
                        <div className="portfolio-section-card-content">
                            <p className="portfolio-section-title">{item.title}</p>
                            <p className="portfolio-section-description">{item.description}</p>
                        </div>
                    </div>
                ))}
            </div>
            <a href="https://github.com/fayiskt"
                target="_blank"
                rel="noreferrer"
                className='github-link'>
                    <button 
                    className="btn btn-github">
                    <FaGithub size={25} />
                    Visit My GitHub
                    </button>
            </a>
        </div>
    </section>
  )
}

export default Portfolio