import React from 'react'
import './bottombar.css'

const Bottombar = () => {
  return (
    <div className='bottom-bar'>
    </div>
  )
}

export default Bottombar