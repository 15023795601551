import React, {useState, useContext} from 'react'
import './navbar.css'
import { Link } from "react-scroll";
import {SkillContext} from '../../contexts/SkillContext';

const Navbar = () => {

    const[active, setActive] = useState(false);
    const { skillActive } = useContext(SkillContext);
    const navClick = ()=>{
        setActive(!active);
    }
    const closeMenu = () => {
        setActive(false);
      };
  return (
    <nav id='navbar'>
        <div className='nav-logo'>
            <img className={`navlogo ${skillActive ? 'active-black' : ''}`} src="./FK-logo.png" alt="FK LOGO" />
        </div>
        <div className={`hamburger-menu ${active? "nav-active" : ""}`} onClick={navClick}>
            <span className={`nav-line ${active? "nav-active" : ""}`}></span>
            <span className={`nav-line ${active? "nav-active" : ""}`}></span>
            <span className={`nav-line ${active? "nav-active" : ""}`}></span>
        </div>
        <div className={`nav-links ${active? "nav-active" : ""}`}>
            <ul className='nav-link-container'>
                <li  className='nav-link-items'>
                    <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="mainSection"
                    >
                    HOME
                    </Link>
                </li>
                <li className='nav-link-items'>
                  <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="skills"
                    >
                    SKILLS
                  </Link>
                </li>
                <li className='nav-link-items'>
                    <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="portfolio"
                    >
                    PORTFOLIO
                    </Link>
                </li>
                <li className='nav-link-items'>
                    <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="about"
                    >
                    ABOUT ME
                    </Link>
                </li>
                <li className='nav-link-items'>
                  <Link
                      onClick={closeMenu}
                      activeClass="navbar--active-content"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      to="contact"
                      >
                      CONTACT ME
                  </Link>
                </li>
            </ul>
        </div>
    </nav>
  )
}

export default Navbar