import React, { useState, useEffect } from 'react';
import './trivia.css';

const TriviaGame = () => {
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [points, setPoints] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const [message, setMessage] = useState('');
  const [showFeedback, setShowFeedback] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      try {
        const response = await fetch('https://opentdb.com/api.php?amount=20&type=multiple');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        const formattedQuestions = data.results.map(question => {
          const options = [...question.incorrect_answers, question.correct_answer];
          return {
            ...question,
            options: options.sort(() => Math.random() - 0.5),
            correctAnswerIndex: options.indexOf(question.correct_answer),
          };
        });
        setQuestions(formattedQuestions);
      } catch (error) {
        console.error('Error fetching questions:', error);
        setQuestions([]);
        setMessage('Trivia questions not available now. Please try later.');
      } finally {
        setLoading(false);
      }
    };
    fetchQuestions();
  }, []);

  const handleAnswerClick = (index) => {
    const currentQuestion = questions[currentQuestionIndex];
    const isCorrect = index === currentQuestion.correctAnswerIndex;

    setSelectedAnswer(index);
    setShowFeedback(true);
    setTimeout(() => {
      setShowFeedback(false);
      setSelectedAnswer(null);
      if (isCorrect) {
        setPoints(points + 5);
        if (currentQuestionIndex < questions.length - 1) {
          setCurrentQuestionIndex(currentQuestionIndex + 1);
        } else {
          setMessage('Congratulations! You answered all questions correctly.');
          setGameOver(true);
        }
      } else {
        setMessage('Game Over. Your total points: ' + points);
        setGameOver(true);
      }
    }, 1000); // Delay to show feedback
  };

  const retryGame = () => {
    setLoading(true);
    setGameOver(false);
    setMessage('');
    setPoints(0);
    setCurrentQuestionIndex(0);
    const fetchQuestions = async () => {
      try {
        const response = await fetch('https://opentdb.com/api.php?amount=20&type=multiple');
        if (!response.ok) throw new Error('Network response was not ok');
        const data = await response.json();
        const formattedQuestions = data.results.map(question => {
          const options = [...question.incorrect_answers, question.correct_answer];
          return {
            ...question,
            options: options.sort(() => Math.random() - 0.5),
            correctAnswerIndex: options.indexOf(question.correct_answer),
          };
        });
        setQuestions(formattedQuestions);
      } catch (error) {
        console.error('Error fetching questions:', error);
        setQuestions([]);
        setMessage('Trivia questions not available now. Please try later.');
      } finally {
        setLoading(false);
      }
    };
    fetchQuestions();
  };

  if (loading) {
    return (
      <div className="trivia-game">
        <h1 className='trivia-heading'>Try Some Trivia</h1>
        <div className="trivia-card">
          <div className="loading-message">Loading Trivia...</div>
        </div>
      </div>
    );
  }

  if (!questions.length) {
    return (
      <div className="trivia-game">
        <h1 className='trivia-heading'>Try Some Trivia</h1>
        <div className="trivia-card">
          <div className="loading-message">{message}</div>
          <button className="retry-button" onClick={retryGame}>Retry</button>
        </div>
      </div>
    );
  }

  const currentQuestion = questions[currentQuestionIndex];
  const options = currentQuestion.options;
  const correctAnswerIndex = currentQuestion.correctAnswerIndex;

  return (
    <div className="trivia-game">
      <h1 className='trivia-heading'>Try Some Trivia</h1>
      <div className="trivia-card">
        <div className="points">Points: {points}</div>
        {gameOver ? (
          <div className="game-over">
            <p>{message}</p>
            <p className="disclaimer">Powered by Open Trivia DB. We cannot be held accountable for any inaccuracies in the trivia content. 😊</p>
            <button onClick={retryGame}>Retry</button>
          </div>
        ) : (
          <div className="question-section">
            <div className="question-number">Question {currentQuestionIndex + 1}</div>
            <div className="question" dangerouslySetInnerHTML={{ __html: currentQuestion.question }} />
            <div className="options">
              {options.map((option, index) => (
                <button
                  key={index}
                  onClick={() => handleAnswerClick(index)}
                  className={`option-button ${
                    showFeedback
                      ? index === correctAnswerIndex
                        ? 'correct'
                        : selectedAnswer === index
                        ? 'incorrect'
                        : ''
                      : ''
                  }`}
                  dangerouslySetInnerHTML={{ __html: option }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TriviaGame;
