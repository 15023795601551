import Navbar from "./components/navbar/Navbar";
import Main from "./pages/home/main-section/Main";
import Bottombar from "./components/bottom-bar/Bottombar";
import Skills from "./pages/home/skills-section/Skills";
import SkillContextProvider from "./contexts/SkillContext";
import Portfolio from "./pages/home/portfolio-section/Portfolio";
import About from "./pages/home/about-section/About";
import Contact from "./pages/home/contact-section/Contact";
import Footer from "./pages/home/footer-section/Footer";
// import News from "./pages/home/news-section/News";
import TriviaGame from "./pages/home/trivia-section/Trivia";

function App() {
  return (
    <div className="App">
      <SkillContextProvider>
        <Navbar/>
        <Main/>
        <Skills/>
        <Portfolio/>
        <About/>
        <Contact/>
        {/* <News/> */}
        <TriviaGame/>
        <Footer/>
        <Bottombar/>
      </SkillContextProvider>
    </div>
  );
}

export default App;
